/* YourToastStyles.css */
/* Increase the width of the toast */
.custom-toast {
    width: 600px; /* Adjust the width as needed */
    background-color:#0f277f; /* Change to your desired background color */
    color: #ffffff; /* Change to your desired text color */
    margin-left: -100px;
    border-radius: 10px;
    max-height: 300px; /* Adjust the height as needed */
    overflow-y: auto;
  }
  
  /* Change the background color of the toast */
  .custom-toast.toast-success {
    background-color: #1abc9c; /* Change to your desired background color */
  }
  
  /* Change the text color of the toast */
  .custom-toast.toast-success .Toastify__toast-body {
    color: #ffffff; /* Change to your desired text color */
  }
  