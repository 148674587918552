@import url(https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App {
  text-align: center;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
/* .slick-dots {
  display: flex;
  justify-content: center;
}

.slick-dots li {
margin-left: "-100px";
} */

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.card-counter {
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  margin: 5px;
  padding: 20px 10px;
  background-color: #fff;
  height: 100px;
  border-radius: 5px;
  transition: 0.3s linear all;
}

.card-counter:hover {
  box-shadow: 4px 4px 20px #dadada;
  transition: 0.3s linear all;
}

.card-counter.primary {
  background-color: #007bff;
  color: #fff;
}

.card-counter.danger {
  background-color: #ef5350;
  color: #fff;
}

.card-counter.success {
  background-color: #66bb6a;
  color: #fff;
}

.card-counter.info {
  background-color: #26c6da;
  color: #fff;
}

.card-counter i {
  font-size: 5em;
  opacity: 0.2;
}

.card-counter .count-numbers {
  position: static;
  right: 35px;
  top: 20px;
  font-size: 32px;
  display: block;
}

.card-counter .count-name {
  position: static;
  right: 35px;
  top: 65px;
  font-style: italic;
  text-transform: capitalize;
  opacity: 0.5;
  display: block;
  font-size: 18px;
}

.bubbly-button {
  font-family: "Helvetica", "Arial", sans-serif;
  display: inline-block;
  font-size: 1em;
  padding: 1em 2em;
  margin-top: 100px;
  margin-bottom: 60px;
  -webkit-appearance: none;
  appearance: none;
  background-color: #007bff;
  color: #fff;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  position: relative;
  transition: transform ease-in 0.1s, box-shadow ease-in 0.25s;
  box-shadow: 0 2px 12px #007bff;
}
.bubbly-button:focus {
  outline: 0;
}
.bubbly-button:before,
.bubbly-button:after {
  position: static;
  content: "";
  display: block;
  width: 140%;
  height: 100%;
  left: -20%;
  z-index: -1000;
  transition: all ease-in-out 0.5s;
  background-repeat: no-repeat;
}
.bubbly-button:before {
  display: none;
  top: -75%;
  background-image: radial-gradient(circle, #08509c 20%, transparent 20%),
    radial-gradient(circle, transparent 20%, #ff0081 20%, transparent 30%),
    radial-gradient(circle, #007bff 20%, transparent 20%),
    radial-gradient(circle, #007bff 20%, transparent 20%),
    radial-gradient(circle, transparent 10%, #007bff 15%, transparent 20%),
    radial-gradient(circle, #007bff 20%, transparent 20%),
    radial-gradient(circle, #007bff 20%, transparent 20%),
    radial-gradient(circle, #007bff 20%, transparent 20%),
    radial-gradient(circle, #007bff 20%, transparent 20%);
  background-size: 10% 10%, 20% 20%, 15% 15%, 20% 20%, 18% 18%, 10% 10%, 15% 15%,
    10% 10%, 18% 18%;
}
.bubbly-button:after {
  display: none;
  bottom: -75%;
  background-image: radial-gradient(circle, #007bff 20%, transparent 20%),
    radial-gradient(circle, #ff0081 20%, transparent 20%),
    radial-gradient(circle, transparent 10%, #ff0081 15%, transparent 20%),
    radial-gradient(circle, #ff0081 20%, transparent 20%),
    radial-gradient(circle, #ff0081 20%, transparent 20%),
    radial-gradient(circle, #ff0081 20%, transparent 20%),
    radial-gradient(circle, #ff0081 20%, transparent 20%);
  background-size: 15% 15%, 20% 20%, 18% 18%, 20% 20%, 15% 15%, 10% 10%, 20% 20%;
}
.bubbly-button:active {
  transform: scale(0.9);
  background-color: #007bff;
  box-shadow: 0 2px 25px rgba(255, 0, 130, 0.2);
}

.modal-window {
  position: fixed;
  background-color: rgba(255, 255, 255, 0.55);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  transition: all 0.3s;
}

.modal-window.hide {
  position: fixed;
  background-color: rgba(255, 255, 255, 0.55);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s;
}
.modal-window:target {
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
}
.modal-window > div {
  width: 400px;

  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  position: static;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 2em;
  background: white;
}
.modal-window header {
  font-weight: bold;
}
.modal-window h1 {
  font-size: 150%;
  transform: translateY(-30px);
}

.modal-close {
  color: #aaa;
  line-height: 50px;
  font-size: 80%;
  position: static;
  right: 0;
  text-align: center;
  top: 0;
  width: 70px;
  text-decoration: none;
}
.modal-close:hover {
  color: black;
}

.modal-window > div {
  border-radius: 1rem;
}

.modal-window div:not(:last-of-type) {
  margin-bottom: 15px;
}

.form-input {
  font-family: "Roboto", sans-serif;
  outline: 0;
  width: 100%;
  border: 1px solid #a0a0a0;
  margin: 0 0 15px;
  padding: 15px;
  box-sizing: border-box;
  font-size: 14px;
}

.form-button {
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  outline: 0;
  background-color: #007bff;
  color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 12px #007bff;
  width: 100%;
  border: 0;
  padding: 15px;
  color: #ffffff;
  font-size: 14px;
  -webkit-transition: all 0.3 ease;
  transition: all 0.3 ease;
  cursor: pointer;
}

.gas-text > p {
  font-size: 13px;
  font-style: italic;
  background: #f0efef;
  box-shadow: 0 2px 12px #aaabac;
  margin: 0 0 15px;
  padding: 15px;
}

figure.snip1278 {
  font-family: "Raleway", Arial, sans-serif;
  position: relative;
  overflow: hidden;
  margin: 10px;
  min-width: 230px;
  max-width: 310px;
  height: 380px;
  width: 100%;
  background: #1a1a1a;
  color: #ffffff;
  text-align: center;
  border-radius: 8px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
}
figure.snip1278 * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.35s cubic-bezier(0.25, 0.5, 0.5, 0.9);
  transition: all 0.35s cubic-bezier(0.25, 0.5, 0.5, 0.9);
}
figure.snip1278 .image {
  background-color: #000000;
  width: 100%;
  overflow: hidden;
}
figure.snip1278 .image:after {
  background-color: rgba(0, 0, 0, 0.55);
  position: static;
  content: "";
  top: 0;
  right: 0;
  left: 100%;
  bottom: 0;
}
figure.snip1278:hover > .image:after {
  left: 50% !important;
}

figure.snip1278 img {
  height: 380px;
  vertical-align: top;
}
figure.snip1278 figcaption {
  position: static;
  width: 50%;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  padding: 10px 25px;
}
figure.snip1278 h4,
figure.snip1278 p,
figure.snip1278 .price {
  margin: 0 0 8px;
}
figure.snip1278 h4 {
  position: static;
  width: 50%;
  top: 10px;
  text-transform: uppercase;
  font-weight: 400;
  color: #ffffff;
  letter-spacing: 1px;
  z-index: 1;
}
figure.snip1278 p {
  font-size: 0.7em;
  font-weight: 500;
  line-height: 1.6em;
}
figure.snip1278 .rating {
  position: static;
  width: 50%;
  line-height: 44px;
  color: #ffffff;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
}
figure.snip1278 .price {
  color: #ffffff;
  font-size: 1.3em;
  opacity: 0.8;
}
figure.snip1278 .price s {
  display: inline-block;
  padding: 0 8px 0 0;
  font-size: 0.85em;
  color: #f39c12;
}
figure.snip1278 .add-to-cart {
  text-decoration: none;
  position: static;
  bottom: 0;
  right: 0;
  font-weight: 600;
  width: 50%;
  background-color: rgba(0, 2, 105, 0.2);
  line-height: 44px;
  font-size: 0.75em;
  text-transform: uppercase;
  color: #ffffff;
}
figure.snip1278:hover img,
figure.snip1278.hover img {
  opacity: 0.6;
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
figure.snip1278:hover .add-to-cart,
figure.snip1278.hover .add-to-cart {
  background-color: rgba(0, 0, 0, 0.5);
}

.emp-box {
  background-image: radial-gradient(
    circle 248px at center,
    #16d9e3 0%,
    #30c7ec 47%,
    #46aef7 100%
  ) !important;

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  height: 60px;
  width: 60px;
  margin-left: 20px;
}

.emp-box-adjusted {
  margin-left: 2px !important;
  margin-top: 10px !important;
}

.d-none-imp {
  display: none !important;
}

.emp-box .emp-tag {
  font-size: 12px;
  color: #fff;
  margin-top: 10%;
}

.emp-details .emp-tag {
  color: #94a6b0;
  font-weight: 500;
}

.emp-details {
  padding: 2% 9%;
}

.emp-details .emp-tag.big {
  font-size: 16px;
}

.emp-details .emp-tag.small {
  font-size: 12px;
}
.emp-tag.middle {
  font-size: 14.6px;
}

.add-ship {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.modal-header {
  border: none !important;
}

.fa-clock-o {
  color: #b3c4da !important;
  font-size: 20px;
  margin-right: 10px;
}

.emp-box-app {
  background-color: #006aee;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  height: 30px;
  width: 70px;
  margin-left: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.emp-tag-app {
  color: #fff;
  font-size: 13px;
  margin-top: 20%;
}

.text-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5%;
}

.text-box-2 {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 5%;
}

.card.mb-3 {
  margin-bottom: 30px !important;
}

.widget-content {
  padding: 1rem;
  flex-direction: row;
  align-items: center;
}

.card {
  box-shadow: 0 0.46875rem 2.1875rem rgb(4 9 20 / 3%),
    0 0.9375rem 1.40625rem rgb(4 9 20 / 3%),
    0 0.25rem 0.53125rem rgb(4 9 20 / 5%), 0 0.125rem 0.1875rem rgb(4 9 20 / 3%);
  border-width: 0;
  transition: all 0.2s;
}
.bg-midnight-bloom {
  background-image: linear-gradient(
    -20deg,
    #2b5876 0%,
    #4e4376 100%
  ) !important;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(26, 54, 126, 0.125);
  border-radius: 0.25rem;
}

.widget-content .widget-content-wrapper {
  display: flex;
  width: 90%;
  flex: 1;
  position: relative;
  align-items: center;
}

.widget-content .widget-content-left .widget-heading {
  opacity: 0.8;
  font-weight: bold;
}

.widget-heading.dark,
.widget-subheading.dark {
  color: #282c34 !important;
}

.widget-content .widget-content-left .widget-subheading {
  opacity: 0.5;
}

.widget-content .widget-content-right {
  margin-left: auto;
}

.widget-content .widget-numbers {
  font-weight: bold;
  font-size: 1.8rem;
  display: block;
}

.bg-arielle-smile {
  background-image: radial-gradient(
    circle 248px at center,
    #16d9e3 0%,
    #30c7ec 47%,
    #46aef7 100%
  ) !important;
}

.bg-grow-early {
  background-image: linear-gradient(
    to top,
    #0ba360 0%,
    #3cba92 100%
  ) !important;
}


.side-list a {
  text-decoration: none;
  background-color: rgb(0, 0, 0, 0.5);
}

.side-list a:hover {
  text-decoration: none !important;
}

.draw-side-list a {
  text-decoration: none;
  background-color: rgb(0, 0, 0, 0.5);
}

.draw-side-list a:hover {
  text-decoration: none !important;
}


.item-list:hover {
  box-shadow: 0 0.46875rem 2.1875rem rgb(4 9 20 / 12%),
    0 0.9375rem 1.40625rem rgb(4 9 20 / 12%),
    0 0.25rem 0.53125rem rgb(4 9 20 / 12%),
    0 0.125rem 0.1875rem rgb(4 9 20 / 12%);
    background-color: rgba(249, 245, 245, 0.5);

}

.item-list {
  margin-top: 1% !important;
  position: relative;
  padding-left: 15px !important;
  background: transparent;
  text-decoration: none !important;
  border-radius: 0.5rem;
  margin-bottom: 10px;
  /* box-shadow: 0 0.46875rem 2.1875rem rgb(4 9 20 / 7%),
    0 0.9375rem 1.40625rem rgb(4 9 20 / 7%),
    0 0.25rem 0.53125rem rgb(4 9 20 / 7%), 0 0.125rem 0.1875rem rgb(4 9 20 / 7%); */

  }
  .draw-item-list {
    margin-top: 20% !important;
    position: relative;
    padding-left: 15px !important;
    background: transparent;
    text-decoration: none !important;
    border-radius: 0.5rem;
    margin-bottom: 10px;
    font-size: 30px;
    /* box-shadow: 0 0.46875rem 2.1875rem rgb(4 9 20 / 7%),
      0 0.9375rem 1.40625rem rgb(4 9 20 / 7%),
      0 0.25rem 0.53125rem rgb(4 9 20 / 7%), 0 0.125rem 0.1875rem rgb(4 9 20 / 7%); */
  
    }


  

.item-list h6 {
  color: #0C0C0D;
font-family: Poppins;
font-size: 15.971px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.19px;
margin-top: 10px;
margin-left: 5px;
}
.item-list h6:hover{
  color: #5b3bce;
}

.item-list i {
  width: 29px;
  height: 29px;
  flex-shrink: 0;
}

.list-wrap {
  padding: 0 14px;
}

.bg-white {
  background-color: #fff !important;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form .input {
  background-color: #fff;
  outline: none;
  border: none;
  padding: 20px 40px;
  box-shadow: 0 0.46875rem 2.1875rem rgb(4 9 20 / 3%),
    0 0.9375rem 1.40625rem rgb(4 9 20 / 3%),
    0 0.25rem 0.53125rem rgb(4 9 20 / 5%), 0 0.125rem 0.1875rem rgb(4 9 20 / 3%);
  border-radius: 3.5rem;
  width: 480px;
  font-size: 18px;
  margin-bottom: 15px;
}

.form button {
  background-image: radial-gradient(
    circle 248px at center,
    #16d9e3 0%,
    #30c7ec 47%,
    #46aef7 100%
  ) !important;
  color: #fff;
  font-weight: bold;
  outline: none;
  border: none;
  padding: 20px 40px;
  box-shadow: 0 0.46875rem 2.1875rem rgb(4 9 20 / 3%),
    0 0.9375rem 1.40625rem rgb(4 9 20 / 3%),
    0 0.25rem 0.53125rem rgb(4 9 20 / 5%), 0 0.125rem 0.1875rem rgb(4 9 20 / 3%);
  border-radius: 3.5rem;
  width: 480px;
  font-size: 18px;
  margin-bottom: 15px;
}

input[type="file"] {
  max-width: 480px;
}

::-webkit-file-upload-button {
  background-color: #e0f3ff;
  color: #343a40;
  padding: 0.5rem;
  font-family: sans-serif;
  border-radius: 0.3rem;
  cursor: pointer;
  margin-top: 1rem;
  padding: 20px 40px;
  box-shadow: 0 0.46875rem 2.1875rem rgb(4 9 20 / 3%),
    0 0.9375rem 1.40625rem rgb(4 9 20 / 3%),
    0 0.25rem 0.53125rem rgb(4 9 20 / 5%), 0 0.125rem 0.1875rem rgb(4 9 20 / 3%);
  border-radius: 3.5rem;
  width: 480px;
  display: flex;
  justify-content: center;
}

.form {
  margin-top: 80px;
}

.box {
  position: relative;
  width: 30rem;
  height: 30rem;
  margin: 4rem;
}

.box:hover .imgBox {
  transform: translate(-3.5rem, -3.5rem);
}

.box:hover .content {
  transform: translate(3.5rem, 3.5rem);
}

.imgBox {
  position: static;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  transition: all 0.5s ease-in-out;
  box-shadow: 0 0.46875rem 2.1875rem rgb(4 9 20 / 1%),
    0 0.9375rem 1.40625rem rgb(4 9 20 / 1%),
    0 0.25rem 0.53125rem rgb(4 9 20 / 5%), 0 0.125rem 0.1875rem rgb(4 9 20 / 1%);
}

.imgBox img {
  width: 30rem;
  height: 30rem;
  object-fit: cover;
  resize: both;
}

.content {
  overflow: hidden !important;
  position: relative;
  top: 0;
  left: 0;
  box-shadow: 0 0.46875rem 2.1875rem rgb(4 9 20 / 7%),
    0 0.9375rem 1.40625rem rgb(4 9 20 / 7%),
    0 0.25rem 0.53125rem rgb(4 9 20 / 5%), 0 0.125rem 0.1875rem rgb(4 9 20 / 7%);
  width: 100%;
  height: 100%;
  padding: 1.5rem;
  display: flex;
  justify-content: center;
  background-color: #dbf5fb;
  /* z-index: 1; */
  align-items: flex-end;
  text-align: center;
  transition: 0.5s ease-in-out;
}
.content button i {
  color: #fff;
}
.content button {
  position: static;
  right: -55px;
  bottom: -65px;
  outline: none;
  border: none;
  background-color: red;
  padding-left: 30px;
  padding-top: 20px;
  padding-bottom: 80px;
  padding-right: 80px;
  font-size: 5rem;
  font-weight: 500;
}

.content span {
  color: #555;
  font-size: 1.4rem;
  font-weight: 300;
  letter-spacing: 2px;
}

@media (max-width: 600px) {
  .box:hover .content {
    transform: translate(0, 3.5rem);
  }
  .box:hover .imgBox {
    transform: translate(0, -3.5rem);
  }
}
/*# sourceMappingURL=main.css.map */

.dashboard-dropdown {
  display: flex !important;
  justify-content: center;
}

.dropdown a {
  font-size: 20px;
  color: #000;
  font-weight: 500;
  padding: 20px;
}

.dropdown ul {
  padding: 20px;
  background: #e0f3ff;
}
.dropdown-menu li:hover {
  box-shadow: 0 0.46875rem 2.1875rem rgb(4 9 20 / 3%),
    0 0.9375rem 1.40625rem rgb(4 9 20 / 3%),
    0 0.25rem 0.53125rem rgb(4 9 20 / 3%), 0 0.125rem 0.1875rem rgb(4 9 20 / 7%);
}
.dropdown-menu li {
  padding: 8px;
  display: flex;
  align-items: center;
  background: #e0f3ff;
  text-decoration: none !important;
  border-radius: 0.5rem;
  margin-bottom: 10px;
  box-shadow: 0 0.46875rem 2.1875rem rgb(4 9 20 / 1%),
    0 0.9375rem 1.40625rem rgb(4 9 20 / 1%),
    0 0.25rem 0.53125rem rgb(4 9 20 / 1%), 0 0.125rem 0.1875rem rgb(4 9 20 / 7%);
}
.-menu li a {
  font-size: 18px;
  color: #000;
  font-weight: 500;
}

.dropdown-menu li a:hover {
  background: #e0f3ff !important;
}

ul.nav.navbar-nav li {
  display: inline;
}

.mapouter {
  position: relative;
  text-align: right;
  height: 80vh;
  width: 100%;
}
.gmap_canvas {
  overflow: hidden;
  background: none !important;
  height: 80vh;
  width: 100%;
}
